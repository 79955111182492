import React, {useContext} from 'react';
import NextErrorComponent from 'next/error';
import * as Sentry from '@sentry/nextjs';
import Link from 'next/link';
import {I18nContext} from '../i18n';
import Button from '../components/system/Button';
import Layout, {FooterType} from '../components/rich/Layout';
import {HeaderType} from '../components/rich/Header/types';

type Props = {
	statusCode?: number;
	hasGetInitialPropsRun?: boolean;
	err?: unknown;
};

const Error = (_props: Props) => {
	if (!_props.hasGetInitialPropsRun && _props.err) {
		Sentry.captureException(_props.err);
	}

	const {translate} = useContext(I18nContext);
	return (
		<Layout
			className="flex fixed bottom-0 left-0 right-0 top-0 bg-404"
			header={HeaderType.LOGO_HEADER}
			footer={FooterType.NONE}
		>
			<div className="w-full fixed flex bottom-1/4 justify-center z-0">
				<img
					src="/static/svg/404.svg"
					alt=""
					className="inline-block"
				/>
			</div>

			<div className="w-full fixed bottom-1/4 z-10 hidden lg:flex">
				<img
					src="/static/svg/cactus1.svg"
					className="fixed bottom-1/4 left-1/4"
					alt=""
				/>
				<img
					src="/static/svg/cactus2.svg"
					className="fixed bottom-1/4 right-1/3"
					alt=""
				/>
				<img
					src="/static/svg/cactus3.svg"
					className="fixed bottom-1/4 right-1/5"
					alt=""
				/>
			</div>

			<div className="w-full fixed bottom-1/5 flex justify-center z-20">
				<img src="/static/svg/car.svg" alt="" />
			</div>

			<div className="fixed w-full pt-30 font-family-roboto text-center z-30">
				<h1 className="text-4xl font-bold text-darkBlue-980">
					{translate('error404.1')}
				</h1>
				<h2 className="mb-10 text-xl text-darkBlue-980">
					{translate('error404.2')}
				</h2>

				<div className="inline-block">
					<Link href="/">
						<Button
							variant="green"
							type="button"
							className="text-white rounded-xl text-base"
							ariaLabel={translate('backToHome')}
						>
							{translate('backToHome')}
						</Button>
					</Link>
				</div>
			</div>
		</Layout>
	);
};

Error.getInitialProps = async props => {
	const errorInitialProps = await NextErrorComponent.getInitialProps(props);

	if (props.err) {
		Sentry.captureException(props.err);
		await Sentry.flush(2000);
	}

	return {...errorInitialProps, hasGetInitialPropsRun: true};
};

export default Error;
